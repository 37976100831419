<template>
    <ParentsIndex></ParentsIndex>
</template>

<script>
const ParentsIndex = () => import("@/pages/Components/ParentsIndex.vue");

export default {
    name: "AdministracionIndex",
    components: {ParentsIndex},
    data() {
        return {

        }
    },

    methods: {

    },
    created() {

    }
}
</script>

<style>

</style>
